













import { Vue, Component } from 'vue-property-decorator'

@Component({
  props: {
    username: {
      default: 'johannes'
    }
  }
})

export default class DashUserProfile extends Vue {
  user_profile_url = 'https://cdn.discordapp.com/avatars/653973654576562196/7f1b728e05e5e374e552372acb3d84cd.png'
  data = () => ({
    img: 'https://cdn.discordapp.com/avatars/653973654576562196/7f1b728e05e5e374e552372acb3d84cd.png'
  });
}
