


















/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import DashUserProfile from '@/components/DashUserProfile.vue'
import axios from 'axios'
import '@stripe/stripe-js'

const stripe = window.Stripe!('pk_test_avg2lBvVlNEDUT3tI7F70u0U00XjHAri6w')
const elements = stripe.elements()
let card: any

@Component({
  components: {
    DashUserProfile
  }
})
export default class Dashboard extends Vue {
  private username!: string;
  private discriminator!: string;
  private discordId!: string;
  private avatar!: string;
  private email!: string;
  private licenseKey!: string;
  private user!: string;
  private updatePaymentMethodProcess = false;

  async mounted (): Promise<void> {
    card = elements.create('card')
    card.mount('#card')
    card.on('change', (change: any) => {
      console.log('change!!!', change)
    })

    this.user = (await this.getSession()).id
    await this.getUser(this.user)
  }

  public async updatePaymentMethod (): Promise<void> {
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: { email: this.email }
      })

      if (error || !paymentMethod) throw new Error('ERROR CREATING PAYMENT METHOD')
      console.log('new paymentMethod:', paymentMethod)

      const { data } = await axios.patch('http://localhost:4200/checkout/payment-method', {
        paymentMethod: paymentMethod.id
      }, { withCredentials: true })

      console.log('updated PaymentMethod:', data)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  private async cancelSubscription (): Promise<void> {
    try {
      const { data } = await axios.delete('http://localhost:4200/checkout/subscription', {
        withCredentials: true
      })

      console.log('deleted Subscription: ', data)
      return data
    } catch (error) {
      console.error('ERROR canceling subscription:', error)
    }
  }

  private async reactivateSubscription (): Promise<void> {
    try {
      const { data } = await axios.post('http://localhost:4200/checkout/reactivate-subscription', {}, {
        withCredentials: true
      })
      console.log('reactivated Subscription', data)
      return data
    } catch (error) {
      console.error('ERROR reactivating Subscription', error)
    }
  }

  async getSession () {
    const { data } = await axios.get('http://localhost:4200/users', { withCredentials: true })
    console.log('user-data', data)
    return data
  }

  async unbind () {
    try {
      const body = JSON.stringify({ key: this.licenseKey })

      const { data } = await axios({
        method: 'post',
        url: 'http://localhost:4200/licenses/unbind',
        headers: { 'Content-Type': 'application/json' },
        data: body,
        withCredentials: true
      })
      if (data.status === 'success') {
        this.$router.push('http://localhost:8080/bind')
      }
    } catch (error) {
      throw Error()
    }
  }

  async getUser (userId: string) {
    if (userId) {
      const { data } = await axios({
        url: 'http://localhost:4200/users/user/' + userId,
        method: 'get',
        withCredentials: true
      })
      console.log('user:', data)
      this.licenseKey = data.license
      this.email = data.email
    } else {
      console.error('NO USER')
    }
  }
}
